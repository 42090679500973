.movie-card {
  position: relative;
  display: flex;
  flex-direction: column;
  background: linear-gradient(135deg, #1c1c1c, #000000);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.6);
  color: white;
  width: 240px;
  transition: transform 0.3s ease-in-out;
}

.movie-rating {
  font-size: 14px;
  display: inline-block;
  background: rgba(255, 255, 255, 0.1);
  padding: 5px 10px;
  border-radius: 4px;
}

@media screen and (min-width: 768px) {
  .movie-card:hover {
    transform: scale(1.05);
  }
}

.movie-poster-wrapper {
  overflow: hidden;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
}

.movie-poster {
  width: 100%;
  height: 320px;
  display: block;
  transition: opacity 0.3s ease-in-out;
}

.movie-info {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.movie-title {
  font-size: 14px;
  text-align: left;
  margin: 0 0 10px;
}

.movie-details {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 10px;
  color: #ccc;
  align-items: center;
}

.movie-overview {
  font-size: 12px;
  color: #aaa;
  line-height: 1.5;
  max-height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin: 0;
  text-align: left;
}

.movie-release-date {
  font-size: 14px;
}

.favorite-button {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #fff;
  padding: 8px;
  transition: background 0.3s;
}

.favorite-button:hover {
  background: rgba(0, 0, 0, 0.7);
  color: #ff4081;
}

.favourite {
  width: 24px;
}

@media (max-width: 600px) {
  .movie-release-date {
    font-size: 12px;
  }

  .movie-poster-wrapper {
    overflow: hidden;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
    height: 280px;
  }

  .movie-rating {
    font-size: 12px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.1);
    padding: 2px 4px;
    border-radius: 4px;
  }

  .movie-poster {
    height: 100%;
  }

  .movie-card {
    width: 100%;
  }

  .movie-title {
    font-size: 13px;
  }

  .movie-details {
    font-size: 12px;
  }
}
