.favoriteMoviesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.favoriteMoviesList {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 20px;
    padding: 0;
    margin: 0;
    width: 100%;
    justify-items: center;
}

.favoriteMoviesText {
    align-self: flex-start;
    color: aliceblue;
    margin-left: 24px;
}

@media (max-width: 600px) {
    .favoriteMoviesList {
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        justify-items: center;
        gap: 8px;
    }
    .favoriteMoviesText {
        font-size: 20px;
    }
}

@media (min-width: 601px) and (max-width: 960px) {
    .favoriteMoviesList {
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }
}


