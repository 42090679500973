.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    padding: 10px 16px;
  }
  
  .navbar-logo {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
  }
  
  .navbar-links {
    list-style-type: none;
    display: flex;
    gap: 1rem;
  }
  
  .navbar-links li {
    display: inline;
  }
  
  .navbar-links a {
    color: white;
    text-decoration: none;
    font-size: 1rem;
  }
  