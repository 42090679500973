.trending-container {
    padding: 20px;
    color: white;
}

.trending-movies {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

h2 {
    font-size: 24px;
}

.heading {
    display: flex;
    align-items: center;
    gap: 10px;
}

.trendingIcon {
    width: 24px;
    height: 24px;
}
