.trending-card {
    position: relative;
    width: 200px;
    margin: 10px;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    background-color: rgba(0, 0, 0, 0.6);
    font-family: 'Roboto', sans-serif;
    transition: transform 0.3s;
}

.trending-poster {
    width: 100%;
    height: auto;
    display: block;
}

.trending-info {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background: rgba(0, 0, 0, 0.85);
    color: #ffffff;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.trending-card:hover .trending-info {
    opacity: 1;
}

.rank-badge {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(255, 0, 0, 0.8);
    color: white;
    font-size: 14px;
    padding: 5px 8px;
    border-radius: 5px;
    font-weight: bold;
    z-index: 1;
}

.trending-title {
    font-size: 18px;
    margin: 0;
    font-weight: bold;
    text-align: center;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.8);
}

.rating {
    font-size: 16px;
    margin-top: 8px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.1);
    padding: 5px 10px;
    border-radius: 4px;
}

.release-date,
.adult {
    font-size: 12px;
    margin: 2px 0;
    text-align: center;
}

.trending-details {
    margin-top: 10px;
    font-size: 12px;
    line-height: 1.4;
    text-align: center;
    opacity: 0.8;
}

@media screen and (max-width: 600px) {
    .trending-title {
        font-size: 14px;
    }
    .trending-card {
        width: 140px;
    }
    .rating {
        font-size: 12px;
        padding: 4px 8px;
     }
}