.searchResultContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.searchResults {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    gap: 20px;
    padding: 0;
    margin: 0;
    width: 100%;
    justify-items: center;
}

@media (max-width: 600px) {
    .searchResults {
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
        justify-items: center;
        gap: 8px;
    }
}

@media (min-width: 601px) and (max-width: 960px) {
    .searchResults {
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }
}

.spinner {
    margin: 40px auto;
    width: 70px;
    text-align: center;
}

.spinner > div {
    width: 18px;
    height: 18px;
    background-color: #999;
    border-radius: 100%;
    display: inline-block;
    animation: bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    animation-delay: -0.16s;
}

@keyframes bouncedelay {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}

.searchResultText {
    align-self: flex-start;
    color: aliceblue;
    margin-left: 24px;
}
