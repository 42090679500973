.carousel-container {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin: 20px 0;
}

.carousel-wrapper {
    display: flex;
    transition: transform 0.5s ease;
    gap: 8px;
}

.carousel-inner {
    display: flex;
    transition: transform 0.5s ease;
}

.carousel-slide {
    max-width: 100%;
    opacity: 1;
    transition: opacity 0.5s ease;
}

.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    z-index: 10;
}

.carousel-button.left {
    left: 10px;
}

.carousel-button.right {
    right: 10px;
}

.carousel-button:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.carousel-button:disabled {
    background-color: rgba(0, 0, 0, 0.3);
}
